.button {
    display: inline-block;
    margin: 20px 30px;
}
.button:hover {
    background: #544;
}

.button img {
    padding: 54px;
    background: #bdd2ce;
    height: 128px;
    width: 128px;
    float: left;
    margin-right: 40px;
}
a.button {
    background: #403136;
    padding: 10px 30px;
    border-radius: 40px;
    color: white;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
}
a.button.selected {
    background: #ce342c;
}
span.button {
    display: flex;
}
span.button  p{
    flex: 1;
}
